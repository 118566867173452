import { messagingService } from '@/business/messagingService'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { FirebaseMessageConfig } from '@/constants/constants'
import deepcopy from 'deepcopy'

// constante para el número de notificaciones por página
const PAGE_SIZE = 10

export default {
  name: 'AlertNotificationComponent',
  // properties
  props: {
    // tamaño de página para la request que trae las alertas
    pageSize: {
      type: Number,
      required: false,
      default: PAGE_SIZE
    },
    // alto del componente
    height: {
      type: String,
      required: false,
      default: () => { return '500' }
    },
    // ancho del componente
    width: {
      type: Number,
      required: false,
      default: () => { return 512 }
    }
  },
  data () {
    return {
      // número de página (para las requests)
      pageNumber: 1,
      // datos del usuario actual (se extraen del localstorage)
      username: null,
      userId: null,
      // variable de estado para el timeout
      timeout: null,
      // para activar/desactivar una animación cuando hay una nueva alerta
      newAlertAnimation: false,
      // para el spinner
      loading: false
    }
  },
  mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    // datos del usuario
    this.userId = localStorage.getItem('userId')
    this.username = localStorage.getItem('username')
    // para traer las alertas
    this.initialize()
  },
  updated () {
  },
  computed: {
    /*
    ...mapState('alertNotification', {
      alerts: state => state.alerts,
      badge: state => state.badge,
      total: state => state.total,
      alertSound: state => state.alertSound
    }),
    */
    ...mapGetters('alertNotification', [
      'getAlerts',
      'getBadge',
      'getTotal',
      'getAlertSound'
    ]),
    // se muestran sólo las alertas que no fueron eliminadas
    filteredAlerts () {
      return this.getAlerts.filter(alert => !alert.delete)
    },
    // para evitar que se muestre un badge con número muy grandes
    badgeComputed () {
      if (this.getBadge > 99) {
        return '99+'
      } else {
        return this.badgeCount
      }
    }
  },
  created () {
    this.timeout = null
    /*
        Vuex watch de la lista de alertas, cuando cambia la primera alerta de la lista
        se activa una animación para indicarlo. Ya que significa que es una nueva alerta.
    */
    this.unwatch = this.$store.watch(
      (state, getters) => getters['alertNotification/getAlerts'],
      (newValue, oldValue) => {
        // comparo la primera alerta de los datos viejos con los datos nuevos
        if ((newValue.length > 0 && oldValue.length > 0 && newValue[0]._id !== oldValue[0]._id) ||
          (newValue.length > 0 && oldValue.length === 0)) {
          clearTimeout(this.timeout)
          this.timeout = null
          const that = this
          // activo por 1 segundo la animación
          that.newAlertAnimation = true
          this.timeout = setTimeout(() => {
            that.newAlertAnimation = false
          }, 1000)
        }
      }
    )
  },
  beforeDestroy () {
    this.unwatch()
    clearTimeout(this.timeout)
    this.timeout = null
  },
  methods: {
    ...mapActions({
      'setAlerts': 'alertNotification/setAlerts',
      'addAlerts': 'alertNotification/addAlerts',
      'readAlert': 'alertNotification/readAlert',
      'readAllAlerts': 'alertNotification/readAllAlerts',
      'deleteAlert': 'alertNotification/deleteAlert',
      'deleteAllAlerts': 'alertNotification/deleteAllAlerts',
      'increaseBadge': 'alertNotification/increaseBadge',
      'reduceBadge': 'alertNotification/reduceBadge',
      'switchAlertSound': 'alertNotification/switchAlertSound',
      'setAlertsTotal': 'alertNotification/setAlertsTotal',
      'setBadge': 'alertNotification/setBadge'
    }),
    /**
     * para el botón del paginador (cargar más alertas)
     */
    async getMoreAlerts () {
      if (this.userId) {
        await this.getAndSetBadgeCount()
        this.pageNumber++
        messagingService.findAlerts(this.pageSize, this.pageNumber, this.userId).then(alerts => {
          this.addAlerts(alerts)
        })
      }
    },
    /**
     * marcar una alerta como leída / no léida
     * @param {*} alert alerta
     */
    readAlertButtonAction (alert) {
      const alertToUpdate = deepcopy(alert)
      alertToUpdate.show = !alertToUpdate.show
      messagingService.updateAlert(alertToUpdate)
      this.readAlert({ _id: alert._id })
      if (alertToUpdate.show) {
        this.reduceBadge()
      } else {
        this.increaseBadge()
      }
    },
    /**
     * eliminar una alerta
     * @param {*} alert alerta
     */
    deleteAlertButtonAction (alert) {
      const alertToUpdate = deepcopy(alert)
      alertToUpdate.delete = true
      messagingService.updateAlert(alertToUpdate)
      this.deleteAlert({ _id: alert._id })
    },
    /**
     * cuando se hace click en una alerta, por el momento no hay nada definido
     * @param {*} notification
     */
    alertClicked (alert) {
      if (!alert.show) {
        this.readAlert(alert)
      }
      // TO DO: programar qué hacer para cada alerta
    },
    /**
     * marca todas las alertas como leídas y actualiza el badge
     */
    async markAllAsRead () {
      await messagingService.readAllAlerts(this.userId)
      this.readAllAlerts({ value: true })
      await this.getAndSetBadgeCount()
    },
    /**
     * elimina todas las alertas y actualiza el badge
     */
    async deleteAll () {
      await messagingService.deleteAllAlerts(this.userId)
      this.deleteAllAlerts()
      await this.getAndSetBadgeCount()
    },
    /**
     * Para inicializar el componente con las alertas del usuario
     */
    async initialize () {
      if (this.userId) {
        await this.getAndSetBadgeCount()
        const totalCount = await messagingService.getAlertsTotal(this.userId)
        this.setAlertsTotal(totalCount)
        messagingService.findAlerts(this.pageSize, this.pageNumber, this.userId).then(alerts => {
          this.setAlerts(alerts)
        })
      }
    },
    /**
     * Para obtener el número de alertas no vistas y setearlo en el store
     */
    async getAndSetBadgeCount () {
      const badge = await messagingService.getAlertsNotRead(this.userId)
      this.setBadge(badge)
    },
    /**
     * Para formatear la fecha y hora de las alertas
     * @param {*} dateTime
     */
    parseDateTime (dateTime) {
      const momentDate = moment(dateTime)
      return momentDate.format('DD/MM/YY-HH:mm:ss')
    },
    /**
     * Para saber el ícono y color de cada alerta
     * @param {*} alert
     */
    getType (alert) {
      return FirebaseMessageConfig[alert.type]
    }
  }
}
