import AlertNotificationComponent from '@/components/commons/alert-notification/AlertNotificationComponent.vue'

export default {
  name: 'AlertReportComponent',
  components: {
    AlertNotificationComponent
  },
  props: {},
  data: () => ({
  }),
  created () {
  },
  async mounted () {},
  computed: {},
  methods: {}
}
